@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

a {
  @apply underline text-pink-500 hover:text-sky-500;
}

@layer utilities {
  @variants responsive {
    .text-shadow {
      text-shadow: 2px 2px 4px black;
    }
    .text-shadow-md {
      text-shadow: 4px 4px 8px black;
    }
    .text-shadow-lg {
      text-shadow: 15px 15px 30px black;
    }
    .text-shadow-none {
      text-shadow: none;
    }
  }
}
